import React  from 'react';
//import Calendario  from './Calendario';

const Home = () => {
    return (
        //<Calendario/>
        <div className="logo-home">
            <img src="https://estarmejorviandas.com.ar/wp-content/uploads/2015/08/logo6.jpg" alt="Logo" />
        </div>
    );
  }
  
  export default Home;
  