import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { ToggleButton } from 'primereact/togglebutton';

import { Toolbar } from 'primereact/toolbar';

import Rutinas from '../Rutinas';
import Imprimir from '../Impresion';

const Nuevos = () => {

    const [pedidos, setPedidos] = useState([]);
    const [listaViandas, setListaViandas] = useState([]);
    const [subProducto, setSubProducto] = useState(true);

    //CLIENTES
    const [clientes, setClientes] = useState([]);
    const [clienteSeleccionado, setClienteSeleccionado] = useState([]);

    //VIANDAS
    const [cantidadViandas, setCantidadViandas] = useState(1);
    const [cantidadExtras, setCantidadExtras] = useState(1);
    const [viandas, setViandas] = useState([]);

    //ACOMPAÑAMIENTOS
    const [acompañamientos, setAcompañamientos] = useState([]);

    //EXTRAS
    const [extras, setExtras] = useState([]);
    const [productoSeleccionado, setProductoSeleccionado] = useState([]);  
    const [pedidoSeleccionado, setPedidoSeleccionado] = useState([]);    

    const [nuevoPedidoDialog, setNuevoPedidoDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);

    const [deletePedidoDialog, setDeletePedidoDialog] = useState(false);

    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);

    const rutinas = new Rutinas();
    const imprimir = new Imprimir();
    const APIPEDIDOS = rutinas.apiPedidos();
    const APIPEDIDOSESTADOS = rutinas.apiPedidosEstados();
    const APICLIENTES = rutinas.apiUsuario();
    const APIPRODUCTOS = rutinas.apiProductos();
    
    //PETICIONES
    //PEDIDOS ------> OBTENEMOS TODOS LOS PEDIDOS NUEVOS -------------------------------------------------->
    const pedidosGet=async()=>{   
        await axios.get(APIPEDIDOS + "?status=1")     
            .then(response => {
                setPedidos(response.data)
            })
            .catch(error=>{ console.log(error) })
    }

    useEffect(()=>{
        pedidosGet()
        clientesGet()
        viandasGet()
        acompañamientosGet()
        extrasGet()
    },[])  

    const onRowExpand = (event) => {
        // toast.current.show({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    }

    const onRowCollapse = (event) => {
        //toast.current.show({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    }

    const expandAll = () => {
        let _expandedRows = {};
        pedidos.forEach(p => _expandedRows[`${p.id_pedido}`] = true);

        setExpandedRows(_expandedRows);
    }

    const collapseAll = () => {
        setExpandedRows(null);
    }

    const precioPlantilla = (precio) => {
        return rutinas.formatoMoneda(precio);
    }

    const fechaPlantilla = (fecha) => {
        return Moment(fecha).format('DD/MM/YYYY');
    }

    const formatCalorias = (rowData) =>{
        let _calorias;

        if(Number(rowData.id_producto_calorias) === 1){ _calorias= '150'}
        if(Number(rowData.id_producto_calorias) === 2){ _calorias= '300'}
        return _calorias;
    }


    const rowExpansionTemplate = (data) => {
        return (
            <React.Fragment>
                <div className="orders-subtable">
                    <p>Usuario: <span className="jc-font-italic">{data.nombre}</span> - Email: <span className="jc-font-italic">{data.email}</span></p>
                    <p>Teléfono: <span className="jc-font-italic">{data.telefono}</span> - Dirección: <span className="jc-font-italic">{data.direccion}</span></p>
                    <DataTable id="tabla-items" value={ data.itemsViandas } header="Viandas" className="p-datatable-sm">
                        <Column field="nombre" header="Producto"></Column>
                        <Column field="calorias" header="Calorias" body={formatCalorias}></Column>
                        <Column field="cantidad" header="Cantidad"></Column>
                    </DataTable>
                    <DataTable id="tabla-items" value={ data.itemsAcompañamientos } header="Acompañamientos" className="p-datatable-sm">
                        <Column field="nombre" header="Producto"></Column>
                    </DataTable>
                    <DataTable id="tabla-items" value={ data.itemsExtras } header="Extras" className="p-datatable-sm">
                        <Column field="nombre" header="Producto"></Column>
                        <Column field="" header=""></Column>
                        <Column field="cantidad" header="Cantidad"></Column>
                    </DataTable>                    
                </div>                         
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header-container">
            <Button icon="pi pi-plus" label="" onClick={expandAll} className="p-mr-2" />
            <Button icon="pi pi-minus" label="" onClick={collapseAll} />
        </div>
    );


    /*****************************************************************************************/
    /************************************  MODAL NUEVO      **********************************/
    /*****************************************************************************************/
    //CLIENTE --------------------------------------------------------------------------------->
    const clientesGet=async()=>{ 
        fetch(APICLIENTES)
            .then(response => response.json())
            .then(data => setClientes(data))
            .catch(error => console.log(error))        
    }

    const onClienteChange = (e) => {
        setClienteSeleccionado(e.value);
    }

    const plantillaClienteSeleccionado = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.id_usuario} - {option.email}- {option.nombre}</div>
                </div>
            );
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const clienteOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.id_usuario} - {option.email}- {option.nombre}</div>
            </div>
        );
    }

    
    //VIANDAS--------------------------------------------------------------------------------->
    const viandasGet=async()=>{ 
        fetch(APIPRODUCTOS + "?tp=1")
            .then(response => response.json())
            .then(data => setViandas(data))
            .catch(error => console.log(error)) 
    }

    const pedidoPost = async()=>{
        let pedido = {
            token: '',
            observacion: '',
            cliente: '',
            listaProductos: ''
        };
        pedido.token = rutinas.obtenerToken('token');
        pedido.observacion= 'PEDIDO CREADO POR ADMIN';
        pedido.cliente= clienteSeleccionado;
        pedido.listaProductos = listaViandas;

        await axios.post(APIPEDIDOS, pedido)
            .then(response=>{ 
                pedidosGet() 
                setNuevoPedidoDialog(false)
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Pedido creado con exito', life: 3000 });
            })
            .catch(error=>{ 
                console.log(error) 
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se produjo un error al crear el pedido', life: 3000 });
            })
    }

    const agregarPedido = (tipoProducto) =>{
        if(productoSeleccionado.id_producto > 0){
            let _idSubProducto=0;
            let _subProducto='';
            let _cantidadProductos=0;
            let _id_hash=rutinas.makeHash(10);
            let _precio=0;

            switch (tipoProducto) {
                case 1:
                    if (subProducto === true){ _idSubProducto = 1; _subProducto = '150'; _precio = productoSeleccionado.CAL1} else { _idSubProducto = 2; _subProducto = '300'; _precio = productoSeleccionado.CAL2}        
                    _cantidadProductos = cantidadViandas;
                    break;
                case 3:
                    _cantidadProductos = cantidadExtras
                    break;
                default:
                    break;
            }            
        listaViandas.push({
                            id_hash: _id_hash,
                            id_producto: productoSeleccionado.id_producto, 
                            nombre: productoSeleccionado.nombre, 
                            cantidad: _cantidadProductos, 
                            precio: _precio,
                            precio_total: _precio * _cantidadProductos,
                            tipoProducto:tipoProducto,
                            id_subproducto: _idSubProducto,
                            subproducto: _subProducto,
                            observaciones: ''
                        });        
        setListaViandas(listaViandas)
        setProductoSeleccionado([])
        setCantidadViandas(1)
        setCantidadExtras(1)
        } 
    }

    const savePedido = () => {
        //validar que haya elegido un cliente
        if(clienteSeleccionado.length === 0 || clienteSeleccionado === ''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe elegir un cliente para crear el pedido', life: 3000 });
            return;
        }        
        //validar que la cantidad de viandas sea  >= 14
        let _sum = 0;
        listaViandas.map(item => {                 
            if(item.tipoProducto === 1){
                _sum += item.cantidad
            }
         })
        if(_sum <=13){
            toast.current.show({ severity: 'info', summary: 'Info', detail: 'Debe seleccionar como minimo 14 viandas', life: 3000 });
            return;
        }
        pedidoPost();        
    }

    /*ELIMINAR UN PRODUCTO DE LA LISTA DE PEDIDOS*/

    const deleteProduct = () => {  
        let _listaViandas = listaViandas.filter(val => val.id_hash !== productoSeleccionado.id_hash);
         
        setListaViandas(_listaViandas);
        setDeleteProductDialog(false);
        setProductoSeleccionado([]);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Producto eliminado del pedido', life: 3000 });
    }

    const deletePedido = () => {  
        pedidoDelete(pedidoSeleccionado.id_pedido);        
        setDeletePedidoDialog(false);
        setPedidoSeleccionado([]);    
    }

    const pedidoDelete=async($id)=>{
        let $token = rutinas.obtenerToken('token');
        const data = {
            id: $id,
            token: $token
        };
        await axios.delete(APIPEDIDOS, {data})            
            .then(response=>{ 
                pedidosGet(); 
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Pedido eliminado', life: 3000 });
            })
            .catch(error=>{ 
                console.log(error) 
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se produjo un error al eliminar el pedido', life: 3000 });
            })
      }

    const deleteProductDialogFooter = (hideDeleteDialog, deleteSelected) => { 
        return (
                <React.Fragment>
                    <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
                    <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelected} />
                </React.Fragment>
        )
    };

    const deletePedidoDialogFooter = (hideDeleteDialog, deletePedido) => { 
        return (
                <React.Fragment>
                    <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
                    <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deletePedido} />
                </React.Fragment>
        )
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeletePedidoDialog = () => {
        setDeletePedidoDialog(false);
    }

    const confirmarEliminarProducto= (producto) => {
        setProductoSeleccionado(producto);
        setDeleteProductDialog(true);
    }
    /* FIN ELIMINAR UN PRODUCTO DE LA LISTA DE PEDIDOS*/

    //ACOMPAÑAMIENTOS--------------------------------------------------------------------------------->
    const acompañamientosGet=async()=>{ 
        fetch(APIPRODUCTOS + "?tp=2")
            .then(response => response.json())
            .then(data => setAcompañamientos(data))
            .catch(error => console.log(error)) 
    }


    //EXTRAS------------------------------------------------------------------------------------------->
    const extrasGet=async()=>{ 
        fetch(APIPRODUCTOS + "?tp99")
            .then(response => response.json())
            .then(data => setExtras(data))
            .catch(error => console.log(error)) 
    }

    const onProductosChange = (e) => {
        setProductoSeleccionado(e.value);
    }

    const plantillaProductoSeleccionado = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.nombre}</div>
                </div>
            );
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const productoOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.nombre}</div>
            </div>
        );
    }

    const openNew = () => {
        setNuevoPedidoDialog(true);      
    }

    const hideDialog = () => {
        setCantidadViandas(1)
        setCantidadExtras(1)
        setClienteSeleccionado([])
        setProductoSeleccionado([])
        setSubProducto(true)
        setNuevoPedidoDialog(false);
        setListaViandas([])
    }

    const dialogFooter = (hideDialog, save)=>{
        return(
        <React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={save} />
        </React.Fragment>
        )
    };

    const confirmImprimirSelected = () =>{
        let _listaPedidos= '';
        selectedPedidos.forEach(element => {
            if(_listaPedidos === ''){
                _listaPedidos = element.id_pedido;
            }else{
                _listaPedidos = _listaPedidos + "," + element.id_pedido
            }
        });
        getImpresion(_listaPedidos,1,'');
    }

    const pasarEstado = () =>{
        console.log(selectedPedidos)
        selectedPedidos.forEach(pedido => {
            putEstado(pedido.id_pedido, 2, "Preparacion")
        });
        
    }    

    const imprimirSelected = (rowData) =>{
        let _listaPedidos= '';
        _listaPedidos = rowData.id_pedido;
        getImpresion(_listaPedidos,0, rowData);
    }

    const getImpresion = async(lista, option, rowData) => {
        await axios.get(APIPEDIDOS + "?printer=" + lista)
        .then(response=>{ 
            if (option === 1)
                imprimir.totales(response.data);
            if (option === 0)
                imprimir.individual(response.data, rowData);
            toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Pedido creado con exito', life: 3000 });
        })
        .catch(error=>{ 
            console.log(error) 
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se produjo un error al crear el pedido', life: 3000 });
        })
    }

    const putEstado = async(idPedido, idEstado, descripcion) => {
        let $token = rutinas.obtenerToken('token');
        const data = {
            id_pedido: idPedido,
            id_pedido_estado: idEstado,
            descripcion_estado: descripcion,
            token: $token,
            fecha_entrega:'null'
        };
        console.log(data)
        await axios.put(APIPEDIDOSESTADOS, data)
        .then(response=>{ 
            pedidosGet(); 
            toast.current.show({ severity: 'success', summary: 'Exito', detail: 'El pedido paso a ' + descripcion  , life: 3000 });
        })
        .catch(error=>{ 
            console.log(error) 
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se produjo un error al pasar el pedido a ' + descripcion, life: 3000 });
        })
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="jc-button-span p-button-success p-mr-2" onClick={openNew} />
                <Button label="Imprimir" icon="pi pi-print" className="jc-button-span p-button-info p-mr-2" onClick={confirmImprimirSelected} disabled={!selectedPedidos || !selectedPedidos.length} />
                <Button label="a Preparar" icon="pi pi-forward" className="jc-button-span p-button-warning p-mr-2" onClick={pasarEstado} disabled={!selectedPedidos || !selectedPedidos.length} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
            <div className="jc-acciones-pedidos">
                <Button icon="pi pi-print" className="p-button-rounded p-button-info p-mr-2" onClick={() => imprimirSelected(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2" onClick={() => eliminarPedido(rowData)} tooltip="Cancelar pedido" />                
            </div>   
            </React.Fragment> 
        );
    }

    const eliminarPedido = (pedido) => {
        setDeletePedidoDialog(true)
        setPedidoSeleccionado(pedido)
    }
    
    const actionBodyProductosPedido = (rowData) => {
        return (
            <React.Fragment>
            <div className="jc-acciones-productos-pedido">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-text" onClick={() => confirmarEliminarProducto(rowData)} tooltip="Editar producto del pedido" />
            </div>   
        </React.Fragment>  
        );
    }

    const [selectedPedidos, setSelectedPedidos] = useState(null);
    return (

        <div className="card">
            <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
            <div className="datatable-rowexpansion-pedidos">            
                <Toast ref={toast} />
                <div className="card">
                    <h2 className="p-m-0 center">Pedidos Nuevos</h2>
                    <DataTable value={pedidos} expandedRows={expandedRows} rowHover  onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={onRowExpand} onRowCollapse={onRowCollapse}
                        rowExpansionTemplate={rowExpansionTemplate} dataKey="id_pedido" header={header}
                        selection={selectedPedidos} onSelectionChange={e => setSelectedPedidos(e.value)}>
                        <Column expander style={{ width: '3em' }} />
                        <Column field="id_pedido" header="Nro" />
                        <Column field="nombre" header="Cliente" />
                        <Column field="fecha_alta" header="Ingresado" body={(e) => fechaPlantilla(e.fecha_alta)} />
                        <Column field="pedido_total" header="Total" body={(e) => precioPlantilla(e.pedido_total)} />
                        <Column selectionMode="multiple" style={{width:'3em'}}/>
                        <Column body={actionBodyTemplate} ></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog visible={nuevoPedidoDialog} style={{ width: '450px' }} header="Nuevo pedido" modal className="dialog-productos jc-dialog-agregar-pedido p-fluid" footer={dialogFooter(hideDialog, savePedido)} onHide={hideDialog}>
                <div className="p-col-12 jc-dropdown-clientes">
                    <div className="p-inputgroup">
                        <Dropdown value={clienteSeleccionado} options={clientes} onChange={onClienteChange} optionLabel="nombre" filter showClear filterBy="id_usuario,nombre,email" placeholder="Seleccionar un cliente"
                        valueTemplate={plantillaClienteSeleccionado} itemTemplate={clienteOptionTemplate} />
                    </div>                    
                </div>
                <div className="p-formgroup-inline jc-div-agregar-pedido">
                        <div className="p-field jc-dropdown-productos">
                            <div className="p-inputgroup">
                                <Dropdown value={productoSeleccionado} options={viandas} onChange={onProductosChange} optionLabel="nombre" filter showClear filterBy="nombre" placeholder="Seleccionar un producto"
                                valueTemplate={plantillaProductoSeleccionado} itemTemplate={productoOptionTemplate} />
                            </div> 
                        </div>
                        <div className="p-field jc-div-calorias">
                            <ToggleButton checked={subProducto} onChange={(e) => setSubProducto(e.value)} onLabel="150 Kal." offLabel="300 Kal." style={{width: '55px'}}/>
                        </div>
                        <div className="p-field jc-div-cantidad">
                            <InputNumber id="cantidadViandas" value={cantidadViandas} onValueChange={(e) => setCantidadViandas(e.value)} showButtons min={1} max={100} />
                        </div>
                        <Button type="button" label="+" className="jc-button" onClick={() => agregarPedido(1)}/>
                </div>
                <div className="p-formgroup-inline jc-div-agregar-pedido">
                        <div className="p-field jc-dropdown-productos">
                            <div className="p-inputgroup">
                                <Dropdown value={productoSeleccionado} options={acompañamientos} onChange={onProductosChange} optionLabel="nombre" filter showClear filterBy="nombre" placeholder="Seleccionar un producto"
                                valueTemplate={plantillaProductoSeleccionado} itemTemplate={productoOptionTemplate} />
                            </div> 
                        </div>
                        <div className="p-field jc-div-calorias">
                        <ToggleButton checked={false} disabled onLabel="N/A" offLabel="N/A" style={{width: '55px'}}/>
                        </div>
                        <div className="p-field jc-div-cantidad">
                        <InputNumber id="cantidadExtras" value={1} disabled showButtons min={1} max={100} />
                        </div>                        
                        <Button type="button" label="+" className="jc-button" onClick={() => agregarPedido(2)}/>
                </div>   
                <div className="p-formgroup-inline jc-div-agregar-pedido">
                        <div className="p-field jc-dropdown-productos">
                            <div className="p-inputgroup">
                                <Dropdown value={productoSeleccionado} options={extras} onChange={onProductosChange} optionLabel="nombre" filter showClear filterBy="nombre" placeholder="Seleccionar un producto"
                                valueTemplate={plantillaProductoSeleccionado} itemTemplate={productoOptionTemplate} />
                            </div> 
                        </div>
                        <div className="p-field jc-div-calorias">
                            <ToggleButton checked={false} disabled onLabel="N/A" offLabel="N/A" style={{width: '55px'}}/>
                        </div>
                        <div className="p-field jc-div-cantidad">
                            <InputNumber id="cantidadExtras" value={cantidadExtras} onValueChange={(e) => setCantidadExtras(e.value)} showButtons min={1} max={100} />
                        </div>
                        <Button type="button" label="+"  className="jc-button" onClick={() => agregarPedido(3)}/>
                </div>              
                <DataTable value={listaViandas} dataKey="id_producto" scrollable scrollHeight="200px">
                    <Column field="id_hash" header="id" bodyStyle={{ display: 'none' }} headerStyle={{ display: 'none' }}/>
                    <Column field="nombre" header="Producto" />
                    <Column field="subproducto" header="Kal." style={{ width: '50px' }}/>
                    <Column field="cantidad" header="Cant." style={{ width: '50px' }}/> 
                    <Column body={actionBodyProductosPedido} style={{ width: '50px' }}></Column>                   
                </DataTable>
            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter(hideDeleteProductDialog,deleteProduct)} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {productoSeleccionado && <span style={{ textAlign: 'center'}}>Estás seguro de que quieres eliminar el producto <br/><div><b>{productoSeleccionado.nombre}</b>?</div></span>}
                </div>
            </Dialog>

            <Dialog visible={deletePedidoDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deletePedidoDialogFooter(hideDeletePedidoDialog,deletePedido)} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {pedidoSeleccionado && <span style={{ textAlign: 'center'}}>Estás seguro de que quieres eliminar el pedido  <br/><div><b>{pedidoSeleccionado.id_pedido}</b>?</div></span>}
                </div>
            </Dialog>
        </div>
    );
}
export default Nuevos;