import React, { useRef } from 'react' //useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { Menu } from 'primereact/menu';

import Rutinas from '../components/Rutinas';

import logo from '../assets/static/logo.png';
import '../assets/style.css';


const SidebarDemo = (props) => {
    const menu = useRef(null);
    const rutinas = new Rutinas();
    const items = [
        {
            label: 'Módulos',
            items: [
                {
                    label: 'Usuarios',
                    icon: 'pi pi-users',
                    url: '/Usuarios'
                },
            ]
        },
        {
            label: 'Productos',
            icon: 'pi pi-apple',
            items:[
                {
                    label: 'Viandas',
                    icon: 'pi pi-chevron-right',
                    url: '/Viandas'
                },
                {
                    label: 'Acompañamientos',
                    icon: 'pi pi-chevron-right',
                    url: '/Acompañamientos'
                },
                {
                    label: 'Tartas',
                    icon: 'pi pi-chevron-right',
                    url: '/Tartas'
                },
                {
                    label: 'Ensaladas',
                    icon: 'pi pi-chevron-right',
                    url: '/Ensaladas'
                },
                {
                    label: 'Pizzas',
                    icon: 'pi pi-chevron-right',
                    url: '/Pizzas'
                },
                {
                    label: 'Postres',
                    icon: 'pi pi-chevron-right',
                    url: '/Postres'
                }

            ]
        },
        {
            label: 'Pedidos',
            icon: 'pi pi-shopping-cart',
            items:[
                {
                    label: 'Nuevos',
                    icon: 'pi pi-chevron-right',
                    url: '/Nuevos'
                },
                {
                    label: 'En Preparación',
                    icon: 'pi pi-chevron-right',
                    url: '/EnPreparacion'
                },
                {
                    label: 'Entregados',
                    icon: 'pi pi-chevron-right',
                    url: '/Entregados'
                },
                {
                    label: 'Cancelados',
                    icon: 'pi pi-chevron-right',
                    url: '/Cancelados'
                }
            ]
        },
        {
            label: 'Estadísticas',
            items: [
                {
                    label: 'Próximamente',
                    icon: 'pi pi-external-link',
                    url: ''
                },
            ]            
        }                   
    ];

    const start = <img className='logo p-mr-2' alt="logo" src={logo} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />                        
    const end  = <div className='card'>
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                    <Button label="Menú" icon="pi pi-bars" onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                </div>

    return ( 
        <React.Fragment>
        <div>
            <div className="card">
                {rutinas.obtenerToken('token')?<Menubar start={start} end={end}></Menubar>: <Menubar start={start}></Menubar>}
            </div>
        </div>       
        <div  className="jc-container">
            <div className="card jc-container-view">
                <div className="layout-content card">
                    {props.children}
                </div>
                

            </div>
        </div>
        </React.Fragment>
    )
}

export default SidebarDemo;
