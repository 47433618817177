import React  from 'react';
import { Rating } from 'primereact/rating';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const api = "https://www.pedidos.estarmejorviandas.com.ar/apiViandas";
//const api = "http://localhost/apiViandas/";

//const menu = useRef(null);
export default class Rutinas {
    
    items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Update',
                    icon: 'pi pi-refresh',
                    command: () => {
                        this.toast.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-times',
                    command: () => {
                        this.toast.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                    }
                }
            ]
        },
    ];

    obtenerToken(name){
        let nameEQ = name + "=";
        let ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,
            c.length);
        }
        return null;
    }

    makeHash(length){
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
        }
        return result;        
    }

    apiProductos(){
        return api + "/productos"; 
    }

    apiPedidos(){
        return api + "/pedidos"; 
    }

    apiPedidosEstados(){
        return api + "/pedidosEstados"; 
    }

    apiCategorias(){
        return api + "/categorias"; 
    }

    apiImagen(){
        return api + "/imagen"; 
    }

    apiRepoImagen(){
        return api; 
    }

    apiUsuario(){
        return api + "/usuarios"; 
    }
    apiAuth(){
        return api + "/auth"; 
    }

    formatoMoneda(value){
        return  Number(value).toLocaleString('es-US', { style: 'currency', currency: 'USD' });
    }

    formatoRating(value) {
        return <Rating value={value} readonly cancel={false} title={value} />;
    }
    
    formatImage(imagen, alt) {
        return <img src={imagen} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={alt} title={alt} className="product-image" />
    }
    header(setGlobalFilter, administrador){
        return(
        <div className="table-header">
            <h3 className="p-m-0">Administrador de {administrador}</h3>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
        )
    };

    accionesUsuarios(editarUsuario, confirmarEliminarUsuario, rowData){
        return(
        <React.Fragment>
            {/* <Button icon="pi pi-home" className="p-button-rounded p-button-info p-mr-2" onClick={() => direcciones(rowData)} /> */}
            <Button icon="pi pi-pencil"  className="p-button-rounded p-button-success p-mr-2" onClick={() => editarUsuario(rowData)} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmarEliminarUsuario(rowData)} />
        </React.Fragment>
        );
    }

    accionesProductos(editarProducto, confirmarEliminarProducto, rowData){
        return(
        <React.Fragment>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editarProducto(rowData)} tooltip="Blue" />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmarEliminarProducto(rowData)} tooltip="Eliminar producto" />
        </React.Fragment>
        );
    }

    accionesPedidos(eliminarPedido, rowData){
        return(
            <React.Fragment>
                <div className="jc-acciones-pedidos">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-mr-2" onClick={() => eliminarPedido(rowData)} tooltip="Editar fecha de entrega" />
                    {/* <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-info p-mr-2" onClick={() => exportPdf(rowData)} /> */}
                </div>   
            </React.Fragment>  
        );
    }

}