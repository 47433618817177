import React from 'react';
import { Button } from 'primereact/button';

export default class Dialog {
    dialogFooter(hideDialog, save){
        return(
        <React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={save} />
        </React.Fragment>
        )
    };

    deleteDialogFooter(hideDeleteDialog, deleteRegistro){
        return (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteRegistro} />
        </React.Fragment>
        )
    };

    deleteRegistroDialogFooter(hideDeleteDialog, deleteSelected){
        return (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelected} />
        </React.Fragment>
        )
    };
}