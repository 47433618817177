import 'jspdf-autotable'
import Moment from 'moment';

const columnasViandas = [
    { field: 'TRESCIENTOS', header: '300' },
    { field: 'nombre', header: 'VIANDAS' },
    { field: 'CIENTOCINCUENTA', header: '150' },
];
const columnasTotales = columnasViandas.map(col => ({ title: col.header, dataKey: col.field }));

const columnasExtras = [
    { field: 'nombre', header: 'EXTRAS' },
    { field: 'CANTIDAD', header: '' },
];

const columnasExtrasT = columnasExtras.map(col => ({ title: col.header, dataKey: col.field }));

const columnasAcompañamientos = [
    { field: 'nombre', header: 'ACOMPAÑAMIENTOS' },
    { field: 'CANTIDAD', header: '' },
];

const columnasAcompañamientosT = columnasAcompañamientos.map(col => ({ title: col.header, dataKey: col.field }));

export default class Impresion  {
    totales(impresion){
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.setFontSize(10);                
                doc.autoTable({
                    columnStyles: {
                        0: {columnWidth: 10},
                        1: {columnWidth: 70},
                        2: {columnWidth: 10},
                      },
                    headStyles:{
                        valign: 'middle',
                        halign : 'center'
                      },
                    theme: 'grid', // 'striped', 'grid' or 'plain'
                    margin: { top: 10 },
                    body : impresion.impresionViandas,
                    columns:columnasTotales,
                  })
                doc.autoTable({
                    columnStyles: {
                        0: {columnWidth: 80},
                        1: {columnWidth: 10},
                      },
                    headStyles:{
                        valign: 'middle',
                        halign : 'center'
                      },
                    theme: 'grid', // 'striped', 'grid' or 'plain'
                    //margin: { top: 10 },
                    body : impresion.impresionExtras,
                    columns:columnasExtrasT,
                  })  
                doc.autoTable({
                    columnStyles: {
                        0: {columnWidth: 80},
                        1: {columnWidth: 10},
                      },    
                    headStyles:{
                        valign: 'middle',
                        halign : 'center'
                      },                
                    theme: 'grid', // 'striped', 'grid' or 'plain'
                    //margin: { top: 10 },
                    body : impresion.impresionAcompañamientos,
                    columns:columnasAcompañamientosT,
                  })   
                var f = new Date();
                let nombre = "pedido_" + f.getDate() + (f.getMonth() +1) + f.getFullYear()+ f.getHours() + f.getMinutes() + f.getSeconds();
                doc.save(nombre + '.pdf');                                 
            })
        })
        
        return null;
    }

    individual(impresion, rowData){ 
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.setFontSize(10);
                doc.text("Fecha Pedido: ".concat(Moment(rowData.fecha_alta).format('DD/MM/YYYY')) ,15,10);
                doc.text("Fecha Entrega: ",15,15);
                doc.text("Cliente: ".concat(rowData.nombre) ,15,20);
                doc.text("Tel/Cel: ".concat(rowData.telefono) ,15,25);
                doc.text("Dirección: ",15,30);
                doc.text("Localidad: ",15,35);
                doc.autoTable({
                    columnStyles: {
                        0: {columnWidth: 10},
                        1: {columnWidth: 70},
                        2: {columnWidth: 10},
                      },
                    headStyles:{
                        valign: 'middle',
                        halign : 'center'
                      },
                    theme: 'grid', // 'striped', 'grid' or 'plain'
                    margin: { top: 40 },
                    body : impresion.impresionViandas,
                    columns:columnasTotales,
                  })
                doc.autoTable({
                    columnStyles: {
                        0: {columnWidth: 80},
                        1: {columnWidth: 10},
                      },
                    headStyles:{
                        valign: 'middle',
                        halign : 'center'
                      },
                    theme: 'grid', // 'striped', 'grid' or 'plain'
                    margin: { top: 0 },
                    body : impresion.impresionExtras,
                    columns:columnasExtrasT,
                  })  
                doc.autoTable({
                    columnStyles: {
                        0: {columnWidth: 80},
                        1: {columnWidth: 10},
                      },    
                    headStyles:{
                        valign: 'middle',
                        halign : 'center'
                      },                
                    theme: 'grid', // 'striped', 'grid' or 'plain'
                    margin: { top: 0 },
                    body : impresion.impresionAcompañamientos,
                    columns:columnasAcompañamientosT,
                  })   
                var f = new Date();
                let nombre = "pedido_" + f.getDate() + (f.getMonth() +1) + f.getFullYear()+ f.getHours() + f.getMinutes() + f.getSeconds();
                doc.save(nombre + '.pdf');                     
            })
        })
    }
}
