import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import DialogProductos from '../Dialog';
import Rutinas from '../Rutinas';

const Tartas = () => {

    //CONSTANTES
    let emptyProduct = {
        id_producto: null,
        id_producto_categoria:null,
        id_producto_tipo:3,
        nombre: '',
        descripcion:'',
        imagen: 'http://localhost:3000/static/media/logo.1e366741.png',
        precio: 0,
        token: '',
        CAL1: 0,
        CAL2: 0,        
    };
    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedProductos, setSelectedProductos] = useState(null);
    
    const toast = useRef(null);
    const dt = useRef(null);
    const rutinas = new Rutinas();
    
    const APIPRODUCTOS = rutinas.apiProductos();

    const dialogProductos = new DialogProductos();

    const productosGet=async()=>{ 
        await axios.get(APIPRODUCTOS + "?tp=3")            
            .then(response=>{          
                console.log(response.data)       
                setProducts(response.data);               
            })
            .catch(error=>{ console.log(error) })
      }

    useEffect(() => {
        productosGet();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    //FUNCIONES DE LA TABLA  
    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const saveProduct = () => {
        setSubmitted(true);
        if (product.nombre.trim()) {
            product.token = rutinas.obtenerToken("token");  
            product.id_producto_categoria = 6;
            product.CAL2 = 0;         
            setProduct(product);    
            console.log(product)
            if (product.id_producto) {
                //TODO:  VALIDAR que esten todos los campos que requiere la API                
                productPut();
            }
            else {
                //TODO:  VALIDAR que esten todos los campos que requiere la API
                productPost();
            }
            setProductDialog(false);
            setProduct(emptyProduct);
        }
    }

    const productPost=async()=>{
        console.log(product);
        await axios.post(APIPRODUCTOS, product)
            .then(response=>{ 
                productosGet() 
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Producto creado', life: 3000 });
            })
            .catch(error=>{ 
                console.log(error) 
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se produjo un error al crear el producto', life: 3000 });
            })
        }

    const productPut=async()=>{
        await axios.put(APIPRODUCTOS, product)
            .then(response=>{ 
                productosGet() 
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Producto actualizado', life: 3000 });
            })
            .catch(
                error=>{ console.log(error) 
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Su produjo un error al modificar el producto', life: 3000 });
            })
        }  

    const editarProducto = (product) => {
        console.log("AAAA: " + product)
        setProduct({...product});
        setProductDialog(true);
    }

    const confirmarEliminarProducto = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        productDelete(product.id_producto);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
    }

    const productDelete=async($id)=>{
        let $token = rutinas.obtenerToken('token');
        const data = {
            id: $id,
            token: $token
          };
        await axios.delete(APIPRODUCTOS, {data})
            .then(response=>{ 
                productosGet() 
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Producto eliminado ', life: 3000 });
            })
            .catch(
                error=>{ console.log(error) 
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Su produjo un error al eliminar el producto', life: 3000 });
            })
        } 

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = {...product};
        _product[`${name}`] = val;

        setProduct(_product);
    }

    const onInputNumberChange = (e, name) => {
        const val = (e.target && e.target.value) || 0;
        let _product = {...product};
        _product[`${name}`] = parseInt(val, 10);

        setProduct(_product);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    {/* <i className="pi pi-search" /> */}
                    {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" /> */}
                </span>
            </React.Fragment>
        )
    }

    const cardPlantilla = (data) => {
        return(
            <div className="dataview-productos p-col-12">
                <div  id={data.id_producto}  className="product-list-item">
                    <img src={`${data.imagen}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.nombre} />
                    <div className="product-list-detail">
                        <div><span className="product-list-description">Código: </span>{data.id_producto}</div>
                        <div><span className="product-list-description">Nombre: </span>{data.nombre}</div>
                        <div><span className="product-list-description">Descripción: </span>{data.descripcion}</div>
                        <div><span className="product-list-description">Precio: ${data.CAL1}</span></div>
                    </div>
                    <div className="product-list-action">                        
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info p-mr-2" onClick={() => editarProducto(data)} Tooltip="Editar producto" />
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmarEliminarProducto(data)} Tooltip="Eliminar producto" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="datatable-crud-demo datatable-responsive-demo">            
            <Toast ref={toast} />
            <div className="card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable ref={dt} value={products} className="p-datatable-responsive-demo" selection={selectedProductos} 
                    onSelectionChange={(e) => setSelectedProductos(e.value)}
                    dataKey="id_producto" paginator rows={9} rowsPerPageOptions={[9, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords} productos"
                    globalFilter={globalFilter}
                    header={rutinas.header(setGlobalFilter, 'Productos')}
                    scrollable scrollHeight="550px" >

                    <Column selectionMode="multiple" headerStyle={{ width: '1rem'}} bodyStyle={{ width: '1rem'}}></Column>
                    <Column header="" headerStyle={{ width: '20rem' }} body={(e) =>cardPlantilla(e)}></Column>                    
                    <Column field="id_producto" header="" sortable  headerStyle={{ width: '8rem', display: 'none' }} bodyStyle={{ width: '3rem', display: 'none' }}></Column>
                    <Column field="nombre" header="" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field="descripcion" header="" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field="categoria" header="" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field= "CAL1" header="" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field= "CAL2" header="" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                </DataTable>
            </div>
            <Dialog visible={productDialog} style={{ width: '450px' }} header="Detalles de Producto" modal className="dialog-productos p-fluid" footer={dialogProductos.dialogFooter(hideDialog, saveProduct)} onHide={hideDialog}>
                <div className="dialog-productos-div-img">
                    {product.imagen && 
                    <img src={product.imagen} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.imagen} className="dialog-product-image" />}                
                </div>

                <div className="p-field padding-top-10">
                    <label htmlFor="nombre">Nombre</label>
                    <InputText id="nombre" value={product.nombre} onChange={(e) => onInputChange(e, 'nombre')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.nombre })} />
                    {submitted && !product.nombre && <small className="p-invalid">Nombre es requerido.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="descripcion">Descripción</label>
                    <InputTextarea id="descripcion" value={product.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} required rows={3} cols={20} />
                </div>         

                <div className="p-field">
                    <label htmlFor="CAL1">Precio</label>
                    <InputNumber id="CAL1" value={product.CAL1} onValueChange={(e) => onInputNumberChange(e, 'CAL1')} mode="currency" currency="USD" locale="en-AR" placeholder="$" />
                    {submitted && !product.CAL1 && <small className="p-invalid">Precio es requerido.</small>}
                </div>
             
            </Dialog>

            <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirmar" modal footer={dialogProductos.deleteRegistroDialogFooter(hideDeleteProductDialog,deleteProduct)} onHide={hideDeleteProductDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {product && <span>Estás seguro de que quieres eliminar <b>{product.name}</b>?</span>}
                </div>
            </Dialog>
        </div>        
    );
}

export default Tartas;