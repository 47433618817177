import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

// import '../assets/style.css';

import Rutinas from '../components/Rutinas';
import DialogUsuarios from './Dialog';


const Usuarios = () => {
    //CONSTANTES
    let emptyUsuario = {
        id_usuario: '',
        usuario: '',
        nombre: '',
        email: '',
        fechaAlta: '',
        clave:'',
        activo: '',
        token: '' ,
        calle: '',
        numero: '',
        localidad:'' 
    };

    const [usuarios, setUsuarios] = useState([]);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [deleteUsuarioDialog, setDeleteUsuarioDialog] = useState(false);
    const [deleteUsuariosDialog, setDeleteUsuariosDialog] = useState(false);
    const [usuario, setUsuario] = useState(emptyUsuario);
    const [selectedUsuarios, setSelectedUsuarios] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);    
    const rutinas = new Rutinas();
    const dialogUsuarios = new DialogUsuarios();
    const APIUSUARIOS = rutinas.apiUsuario();
    

    //PETICIONES
    //PRODUCTOS
    const usuariosGet=async()=>{ 
        await axios.get(APIUSUARIOS)            
            .then(response=>{ 
                setUsuarios(response.data);               
            })
            .catch(error=>{ console.log(error) })
      }

      const usuariosPost=async()=>{
          console.log(usuario)
        await axios.post(APIUSUARIOS, usuario)
            .then(response=>{ 
                usuariosGet() 
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Usuario creado', life: 3000 });
            })
            .catch(error=>{ 
                console.log(error) 
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se produjo un error al crear el usuario', life: 3000 });
            })
      }

      const usuariosPut=async()=>{
        await axios.put(APIUSUARIOS, usuario)
            .then(response=>{ 
                usuariosGet() 
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Usuario actualizado', life: 3000 });
            })
            .catch(
                error=>{ console.log(error) 
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Su produjo un error al modificar el usuario', life: 3000 });
            })
      }      

      const usuariosDelete=async($id)=>{
        let $token = rutinas.obtenerToken('token');
        const data = {
            id: $id,
            token: $token
        };
        await axios.delete(APIUSUARIOS, {data})            
            .then(response=>{ 
                usuariosGet(); 
                toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Usuario eliminado', life: 3000 });
            })
            .catch(error=>{ 
                console.log(error) 
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se produjo un error al eliminar el usuario', life: 3000 });
            })
      }
          
      useEffect(()=>{              
        usuariosGet();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]) 

    //FUNCIONES DE LA TABLA  

    const openNew = () => {
        setUsuario(emptyUsuario);
        setSubmitted(false);
        setUsuarioDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    }

    const hideDeleteUsuarioDialog = () => {
        setDeleteUsuarioDialog(false);
    }

    const hideDeleteUsuariosDialog = () => {
        setDeleteUsuariosDialog(false);
    }

    const saveUsuario = () => {         
        setSubmitted(true);
        if (usuario.email.trim()) {
            usuario.token = rutinas.obtenerToken('token');
            setUsuario(usuario);                       
            if (usuario.id_usuario) {
                usuariosPut();
            }
            else {
                usuario.usuario = usuario.email.split("@")[0];
                setUsuario(usuario);
                usuariosPost();
            }
            setUsuarioDialog(false);
            setUsuario(emptyUsuario);
        }
    }

    const editarUsuario = (usuario) => {
        setUsuario({...usuario});
        setUsuarioDialog(true);
    }

    const confirmarEliminarUsuario = (usuario) => {
        setUsuario(usuario);
        setDeleteUsuarioDialog(true);
    }

    const deleteUsuario = () => {  
        usuariosDelete(usuario.id_usuario);        
        setDeleteUsuarioDialog(false);
        setUsuario(emptyUsuario);        
    }

    const deleteUsuarios = (id) => {  
        usuariosDelete(id);        
        setDeleteUsuarioDialog(false);
        setUsuario(emptyUsuario);        
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteUsuariosDialog(true);
    }

    const deleteSelectedUsuarios = () => {        
        selectedUsuarios.forEach(element => {
            deleteUsuarios(element.id_usuario)
        });
        setUsuarios(usuariosGet());
        setDeleteUsuariosDialog(false);
        setSelectedUsuarios(null);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _usuario = {...usuario};
        _usuario[`${name}`] = val;

        setUsuario(_usuario);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="jc-button-span p-button-success p-mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="jc-button-span p-button-danger p-mr-2" onClick={confirmDeleteSelected} disabled={!selectedUsuarios || !selectedUsuarios.length} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="jc-button-span p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const cardPlantilla = (data) => {
        return (
            <div className="dataview-usuarios">
                <div className="p-col-12">
                <div  id={data.id_usuario}  className="product-list-item">
                    <div className="product-list-detail">
                        <div><span className="product-list-description">Código: </span>{data.id_usuario}</div>
                        <div><span className="product-list-description">Nombre: </span>{data.nombre}</div>
                        <div><span className="product-list-description">Email: </span>{data.email}</div>
                        <div><span className="product-list-description">Teléfono: </span>{data.telefono}</div> 
                        <div><span className="product-list-description">Dirección: </span>{data.calle} {data.numero}, {data.localidad} </div>                       
                        <div><span className="product-list-description">Fecha de Alta </span> {data.fecha_alta}</div>                       
                    </div>
                    <div className="product-list-action">                        
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info p-mr-2" Tooltip="Editar producto" onClick={() => editarUsuario(data)} />
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" Tooltip="Eliminar producto" onClick={() => confirmarEliminarUsuario(data)} />
                    </div>
                </div>
            </div>
            </div>
            );
    }
    return (
        <div className="datatable-crud-demo datatable-responsive-demo">
            <Toast ref={toast} />
            
            <div className="card">
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable ref={dt} value={usuarios} className="p-datatable-responsive-demo" selection={selectedUsuarios} 
                    onSelectionChange={(e) => setSelectedUsuarios(e.value)}
                    dataKey="id_usuario" paginator rows={9} rowsPerPageOptions={[9, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords} usuarios"
                    globalFilter={globalFilter}
                    header={rutinas.header(setGlobalFilter, 'Usuarios')}
                    scrollable scrollHeight="550px" >

                    <Column selectionMode="multiple" headerStyle={{ width: '1rem'}} bodyStyle={{ width: '1rem'}}></Column>
                    <Column header="" headerStyle={{ width: '20rem' }} body={(e) =>cardPlantilla(e)}></Column>                    
                    <Column field="id_usuario" header="Codigo" sortable  headerStyle={{ width: '8rem', display: 'none' }} bodyStyle={{ width: '3rem', display: 'none' }}></Column>
                    <Column field="usuario" header="Usuario" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field="nombre" header="Nombre" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field="email" header="Email" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field="telefono" header="Teléfono" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field= "calle" header="Calle" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field= "numero" header="Número" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field= "localidad" header="Localidad" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>
                    <Column field="fecha_alta" header="Fecha Alta" sortable headerStyle={{ display: 'none' }} bodyStyle={{ display: 'none' }}></Column>                                                        
                </DataTable>
            </div>

            <Dialog visible={usuarioDialog} style={{ width: '450px' }} header="Detalles de Usuario" modal className="p-fluid" footer={dialogUsuarios.dialogFooter(hideDialog, saveUsuario)} onHide={hideDialog}>
                
                {/* <div className="p-field">
                    <label htmlFor="usuario">Usuario</label>
                    <InputText id="usuario" value={usuario.usuario} onChange={(e) => onInputChange(e, 'usuario')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.usuario })} />
                    {submitted && !usuario.usuario && <small className="p-invalid">El campo usuario es requerido.</small>}
                </div> */}
                <div className="p-field">
                    <label htmlFor="nombre">Nombre</label>
                    <InputText id="nombre" value={usuario.nombre} onChange={(e) => onInputChange(e, 'nombre')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.nombre })} />
                    {submitted && !usuario.nombre && <small className="p-invalid">El campo nombre es requerido.</small>}
                </div>            
                <div className="p-field">
                    <label htmlFor="email">Email</label>
                    <InputText id="email" value={usuario.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.email })} />
                    {submitted && !usuario.email && <small className="p-invalid">El campo email es requerido.</small>}
                </div> 
                <div className="p-field">
                    <label htmlFor="telefono">Teléfono</label>
                    <InputText id="telefono" value={usuario.telefono} onChange={(e) => onInputChange(e, 'telefono')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.telefono })} />
                    {submitted && !usuario.telefono && <small className="p-invalid">El campo teléfono es requerido.</small>}
                </div> 
                <div className="p-field">
                    <label htmlFor="calle">Calle</label>
                    <InputText id="calle" value={usuario.calle} onChange={(e) => onInputChange(e, 'calle')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.calle})} />
                    {submitted && !usuario.calle && <small className="p-invalid">El campo calle es requerido.</small>}
                </div> 
                <div className="p-field">
                    <label htmlFor="numero">Número</label>
                    <InputText id="numero" value={usuario.numero} onChange={(e) => onInputChange(e, 'numero')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.numero})} />
                    {submitted && !usuario.numero && <small className="p-invalid">El campo numero es requerido.</small>}
                </div>  
                <div className="p-field">
                    <label htmlFor="localidad">Localidad</label>
                    <InputText id="localidad" value={usuario.localidad} onChange={(e) => onInputChange(e, 'localidad')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.localidad})} />
                    {submitted && !usuario.localidad && <small className="p-invalid">El campo localidad es requerido.</small>}
                </div>                                               
                
            </Dialog>

            <Dialog visible={deleteUsuarioDialog} style={{ width: '450px' }} header="Confirmar" modal footer={dialogUsuarios.deleteRegistroDialogFooter(hideDeleteUsuarioDialog,deleteUsuario)} onHide={hideDeleteUsuarioDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {usuario && <span>Estás seguro de que quieres eliminar <b>{usuario.usuario}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteUsuariosDialog} style={{ width: '450px' }} header="Confirmar" modal footer={dialogUsuarios.deleteRegistroDialogFooter(hideDeleteUsuariosDialog, deleteSelectedUsuarios)} onHide={hideDeleteUsuariosDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {usuario && <span>Está seguro de que desea eliminar los usuarios seleccionados?</span>}
                </div>
            </Dialog>
        </div>
    );
}


export default Usuarios;