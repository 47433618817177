import React, { Component } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

export class Loading extends Component {

    render() {
        return (
            <div>
                <div className="loading">
                    <div className="loading-spinner">
                        <ProgressSpinner />
                    </div>
                </div>
            </div>
        );
    }
}

export default Loading;