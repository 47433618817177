import React, { useState, useRef } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import Cookies from 'universal-cookie';     

import logo from '../assets/static/logo.png';
import Loading from '../components/Loading';
import Rutinas from '../components/Rutinas';


const Login = (props) => {
    const [login, setLogin] = useState('');
    const [redirect, setRedirect] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const cookies = new Cookies();
    const rutinas = new Rutinas();
    
    const APIAUTH = rutinas.apiAuth();
    const loginPost=async()=>{        
        await axios.post(APIAUTH, login)
            .then(response=>{                                        
                cookies.set('token', response.data.result.token, { path: '/' });               
                if(response.data.result.id > 0){                      
                    setRedirect(true)
                }else{
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Usuario o clave incorrectos', life: 4000 });  
                }
            })
            .catch(error=>{ 
                setLoading(false);  
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Usuario o clave incorrectos', life: 4000 });  
                console.log(error) 
            })
    }

    const submitLogin = () =>{
        setSubmitted(true);
        if((login.email && login.clave)){
            setLoading(true);            
            loginPost();
        }
    }
    
    const header = (
        <img alt="Logo" className='logo p-mr-2' src={logo} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
    );
    const footer = (
        <span>
            <Button label="Ingresar" className="p-button-info"  onClick={submitLogin}></Button>
        </span>
    );
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _login = {...login};
        _login[`${name}`] = val;

        setLogin(_login);
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            submitLogin();
        }
      }

    if(redirect === true){          
        window.location.href = "https://www.pedidos.estarmejorviandas.com.ar/";
    }

    if(loading){
        return <Loading/>
    }else{
        return (
            <div className="login">                
                <Toast ref={toast} position="top-center" />
                <div className="login-body">
                    <form>
                        <Card title="Bienvenido" subTitle="Utilice el formulario para iniciar sesión en el sistema" style={{ width: '25em' }} footer={footer} header={header}>                        
                            <div className="p-field p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-envelope"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText id="email" type="text" value={login.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted &&!login.email })} />
                                        <label htmlFor="inputgroup">Email</label>
                                        {/* {submitted && !data.result.id > 0 && <small className="p-invalid">El campo usuario es requerido.</small>} */}
                                    </span>
                                </div>
                            </div>
                            <div className="p-field p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText id="clave" type="password" value={login.clave} onKeyDown={_handleKeyDown}  onChange={(e) =>  onInputChange(e, 'clave')} required className={classNames({ 'p-invalid': submitted && !login.clave })}/>
                                        <label htmlFor="inputgroup">Clave</label>
                                    </span>
                                </div>
                            </div>                    
                        </Card>
                    </form>                    
                </div>
            </div>
        )
    }
}

export default Login;