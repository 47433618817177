import React from 'react'
import { Redirect, Route } from 'react-router'

let getCookie = name => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,
        c.length);
    }    
   return null;
   };

export default function PrivateRouters({component: Component, last: Last, ...rest}) {
    if (Last === 'Login')
    {
        return (
            <Route {...rest}>
                <Component />
            </Route>
        )
    }
    else{
        return (
            <Route {...rest}>
                { getCookie("token")? <Component />: <Redirect to="/login" />} 
            </Route>
        )
    }
}
