import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


import PrimeReact from 'primereact/utils';

import Home from './components/Home'
import Usuarios from './components/Usuarios'
import ProductosViandas from './components/Productos/Viandas';
import ProductosTartas from './components/Productos/Tartas';
import ProductosEnsaladas from './components/Productos/Ensaladas';
import ProductosPizzas from './components/Productos/Pizzas';
import ProductosPostres from './components/Productos/Postres';
import ProductosAcompañamientos from './components/Productos/Acompañamientos';
import Nuevos from './components/Pedidos/Nuevos'
import EnPreparacion from './components/Pedidos/EnPreparacion'
import Entregados from './components/Pedidos/Entregados'
import Cancelados from './components/Pedidos/Cancelados'  
import Login from './pages/Login'

import Layout from './components/Layout';
import PrivateRouters from './routers/PrivateRouters';

PrimeReact.ripple = true;


function AppRouters() {
//const AppRouters = () => {

  return(
    <BrowserRouter >               
      <Layout>
        <Switch>    
          <Route exact path="/Login" component={Login} />
          <PrivateRouters exact path="/Usuarios" component={Usuarios} />    
          <PrivateRouters exact path="/Viandas" component={ProductosViandas} />
          <PrivateRouters exact path="/Acompañamientos" component={ProductosAcompañamientos} />
          <PrivateRouters exact path="/Tartas" component={ProductosTartas} />
          <PrivateRouters exact path="/Ensaladas" component={ProductosEnsaladas} />
          <PrivateRouters exact path="/Pizzas" component={ProductosPizzas} />
          <PrivateRouters exact path="/Postres" component={ProductosPostres} />
          <PrivateRouters exact path="/Nuevos" component={Nuevos} /> 
          <PrivateRouters exact path="/EnPreparacion" component={EnPreparacion} /> 
          <PrivateRouters exact path="/Entregados" component={Entregados} /> 
          <PrivateRouters exact path="/Cancelados" component={Cancelados} /> 
          <PrivateRouters exact path="/" component={Home} />          
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default AppRouters;