import React from 'react';
import ReactDOM from 'react-dom';

import AppRouters from './App';


ReactDOM.render(
    <AppRouters />,
  document.getElementById('App')
);
